@mixin responsible-gaming($theme) {
  $text-color: map-get($theme, text-color);
  $border-color: map-get($theme, border-color);

  .me-responsible-gaming {
    .responsible-gaming-section {
      @include mobile {
        height: 100vh !important;

        .mat-mdc-card {
          height: 100%;

          .p-field {
            width: 100% !important;
          }
        }
      }

      .mat-mdc-card-content {
        .responsible-gaming-container {
          min-height: 260px;

          form {
            align-items: center;
            margin-block: 25px;

            .mat-mdc-radio-group {
              display: flex;
              gap: 20px;
              justify-content: center;
              margin-bottom: 50px;
              margin-left: 10px;

              :last-child {
                margin-left: 0;
              }
            }

            .p-field:last-of-type {
              align-self: center;
              width: 50%;
            }

            .button-container {
              margin-top: 1rem;
            }
          }
        }

        .limitation-container {
          .mat-mdc-card {
            border: 1px solid $border-color;
            font-weight: 600;

            form {
              gap: 15px;

              .p-field:first-of-type {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
