@mixin casino-tournaments($theme) {
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $border-color: map-get($theme, border-color);
  $font-size: map-get($theme, font-size);
  $grey: map-get($theme, grey);
  $grey-700: map-get($theme, grey-700);
  $radius: map-get($theme, radius);
  $success: map-get($theme, success);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $grey-text: if($is-dark, lighten($grey, 30%), $grey-700);
  $default-content-background: if($is-dark, lighten($background, 5%), $text-on-primary);
  $darker-border: darken($text-on-primary, 30%);

  .casino-tournaments{
    .btn-back{
      border-radius: 50%;
      height: 35px;
      width: 35px;
    }

    casino-tournaments {
      display: block;

      .casino-tournaments-wrapper {
        .tournament {
          &-item {
            margin: 0 !important;
          }

          &-description {
            margin-block: 1.8rem;
          }
        }

        .mat-tab-label {
          flex: 1;

          &-container{
            border-top-left-radius: $radius - 4;
          }
        }

        @include mobile {
          .join-btn {
            margin-top: 1rem;
          }
        }

        .casino-tournament-img {
          border-radius: $radius - 4;
        }

        .mat-tab-label-content {
          color: $text-color;
        }

        .mat-tab-body {
          &-content {
            background: $background;

            .mat-tab-body-content {
              background: transparent;
            }

            .join-btn {
              &.joined {
                opacity: .5;
              }
            }

            .no-result-wrapper {
              background-color: $default-content-background;
              height: calc(100vh - 400px);
              justify-content: center;
              margin-top: 0 !important;

              @include mobile{
                height: calc(100vh - 200px);
              }

              .icon {
                font-size: 80px;
                filter: opacity(0.4);
              }
            }

            .mat-accordion {
              .mat-expansion-panel {
                &-header {
                  background-color: transparent;
                  border: none;

                  &:hover {
                    background-color: transparent;
                  }

                  &-title {
                    h3 {
                      color: $text-color;
                      font-size: $font-size + 3;

                      @include mobile {
                        font-size: $font-size;
                      }
                    }

                    .badge {
                      background: $success;
                      border-radius: 3px;
                      color: $text-on-primary;
                      font-size: $font-size - 3;
                      font-weight: 700;
                      padding: .4rem;
                    }
                  }
                }

                &-body {

                  .cat-title {
                    color: $text-color;
                    font-size: $font-size + 5;
                  }

                  .countdown-remaining {
                    h4 {
                      display: none;
                    }

                    .cell {
                      display: flex;
                      font-weight: 700;
                      margin-right: 4px;

                      .value {
                        div:first-child {
                          display: flex;
                        }
                      }
                    }
                  }

                  @media #{$mat-lt-sm} {
                    padding: 0 8px 8px;
                  }
                }

                &-content {
                  font-size: $font-size - 1;

                  .terms-btn {
                    background: transparent;
                    color: $grey-text;
                    font-size: $font-size;
                    font-weight: 600;
                  }

                  .bordered {
                    border: 1px solid $darker-border;
                    border-radius: $radius - 4;
                  }

                  .mat-tab-body-content {
                    padding: 10px;

                    table {
                      tr {
                        th.text-start {
                          padding: 5px 10px;
                          text-align: start;
                        }

                        td {
                          border-bottom: 1px solid $border-color;
                          padding: 7px 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .custom-tooltip {
    align-items: center;
    background-color: $text-on-primary;
    border-radius: 0;
    clip-path: polygon(15% 30%, 15% 0, 100% 0, 100% 100%, 15% 100%, 15% 70%, 0 50%);
    color: lighten($text-color, 31%);
    display: flex;
    font-size: $font-size - 1;
    font-weight: 600;
    height: 35px;
    margin-inline: 5px;
    padding-left: 18px;
    padding-right: 10px;
  }

  .tournament-terms-dialog {
    @include small-desktop {
      .mat-mdc-dialog-container {
        width: auto;
      }
    }

    .mat-mdc-dialog-container {
      padding: 1rem;
    }

    .mat-card {
      background: transparent;

      &-title {
        .title {
          color: $text-color;
          font-size: $font-size + 3;
          font-weight: 400;
          margin-block: 1rem;
        }
      }

      .terms-paragraf {
        letter-spacing: 0.44px;
        line-height: 20px;
        max-width: 680px;
        text-align: start;

        @media #{$mat-lt-md} {
          max-width: 100%;
        }
      }
    }
  }
}
