@mixin security-page($theme) {
  $primary: map-get($map: $theme, $key: primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);

  .me-security {

    .password-section {
      position: relative;
      padding: 0 15px;

      form-builder {
        input:-webkit-autofill {
          box-shadow: 0 0 0 1000px $text-on-primary inset;
        }

        input {
          &::placeholder {
            color: $text-color
          }
        }

        .alert {
          background-color: $text-on-primary;
        }
      }

      .button-container {
        .mat-button-base {
          color: $text-on-primary;
          margin-left: auto;

          md-icon {
            display: none;
          }
        }
      }

      .box-title {
        color: $primary;
        font-weight: 700;

        position: absolute;
        top: 10px;
        left: 12px;

        md-icon {
          display: none;
        }
      }
    }

    p.italic {
      font-style: italic;
      font-weight: 300;
    }
  }
}
