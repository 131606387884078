@mixin promotions($theme) {
  $primary: map-get($theme, primary);
  $radius: map-get($theme, radius);
  $text-color: map-get($theme, text-color);

  .promotions {
    &-container {
      .promo {
        &-sections {
          flex-direction: column;
          justify-content: start;
        }

        &-description {
          color: $text-color;
        }

        &-title {
          color: $primary;
          display: block;
          font-size: 16px;
          margin-top: 1rem;
        }
      }

      .logo-col,
      .description-col,
      .btn-col {
        flex: 0 1 100%;
        max-width: 100%;
        width: 100%;
      }

      .logo-col img {
        border-radius: $radius;
      }

      .btn-col {
        //justify-content: end !important;
        margin: 0;
        padding: 0 !important;

        .promo-link {
          margin-left: auto;
        }
      }
    }

    .no-data {
      padding-top: 2rem;
    }

    @media #{$mat-gt-l} {
      &-container {
        .flex-column {
          display: flex;
          flex-direction: row !important;
          flex-wrap: wrap;
          gap: 1rem;

          .mat-card:not(:only-child) {
            flex: 1 1 49%;
          }
        }
      }
    }
  }
}
