/* You can add global styles to this file, and also import other style files */

// VENDOR
//@import url('https://fonts.googleapis.com/css2?family=Afacad&family=Oswald&family=Pirata+One&family=Play&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
//  VARIABLES
@import 'scss/variables/theme-variables';
// Base
@import 'scss/base/base';
@import 'scss/base/typography';

// Utilities
@import 'scss/utilities/mixins';
@import 'scss/utilities/scrollbars';
@import 'scss/utilities/utilities';
// Components
@import 'scss/components/material';
@import 'scss/components/material-settings';
@import 'scss/components/buttons';
@import 'scss/components/bottom-bar';
@import 'scss/components/login-dialog';
@import 'scss/components/header';
@import 'scss/components/dialogs';
@import 'scss/components/register-dialog';
@import 'scss/components/forgot-password-dialog';
@import 'scss/components/data-table-builder';
@import 'scss/components/tree-table';
@import 'scss/components/mobile-tickets';
@import 'scss/components/mobile-prefix';
@import 'scss/components/games-grid';
@import 'scss/components/bonuses';
@import 'scss/components/me-container';
@import 'scss/components/banner-slider';
@import 'scss/components/wallets-viewer';
@import 'scss/components/sidebar-menu';
@import 'scss/components/sportsbook-betslip';
@import 'scss/components/footer';
@import 'scss/components/card';
@import 'scss/components/autoexclusion-dialog';
@import 'scss/components/alerts';
@import 'scss/components/deliveries';
@import 'scss/components/top-league';
@import 'scss/components/casino-filter';
@import 'scss/components/loyalty-levels-dialog';
@import 'scss/components/casino-tournaments';
@import 'scss/components/data-table-builder';
@import 'scss/components/play-now-live';
@import 'scss/components/smartico-bar';
@import 'scss/components/casino-navbar';
@import 'scss/components/inputs';
@import 'scss/components/customs';

// Pages
@import 'scss/pages/list-games';
@import 'scss/pages/content-page';
@import 'scss/pages/account/network-pages';
@import 'scss/pages/account/reports-pages';
@import 'scss/pages/me/details-page';
@import 'scss/pages/me/movements-page';
@import 'scss/pages/me/deposit-page';
@import 'scss/pages/me/account-verification';
@import 'scss/pages/me/security-page';
@import 'scss/pages/me/responsible-gaming';
@import 'scss/pages/promotions';
@import 'scss/pages/promotion-details';
@import 'scss/pages/cashback';
@import 'scss/pages/faq';
@import 'scss/pages/loyalty-system';
@import 'scss/pages/page-play-game';
@import 'scss/pages/page-register';
//@import 'scss/pages/sportsbook/live.page';
//@import 'scss/pages/sportsbook/sportsbook-main.page';
@import 'scss/pages/home-games';
@import 'scss/pages/vip';
@import 'scss/pages/affiliate-page';

@mixin theme-core($theme) {
  @include material($theme);
  @include alerts($theme);
  @include login-dialog($theme);
  @include material-settings($theme);
  @include sidebar-menu($theme);
  @include wallets-viewer($theme);
  @include data-table-builder($theme);
  @include tree-table($theme);
  @include register-dialog($theme);
  @include mobile-tickets($theme);
  @include forgot-password-dialog($theme);
  @include dialogs($theme);
  @include autoexclusion-dialog($theme);
  @include games-grid($theme);
  @include header($theme);
  @include me-container($theme);
  @include bottom-bar($theme);
  @include footer($theme);
  @include card($theme);
  @include casino-filter($theme);
  @include top-league($theme);
  @include casino-tournaments($theme);
  @include deliveries($theme);
  @include play-now-live($theme);
  @include loyalty-levels-dialog($theme);
  //@include sportsbook-betslip($theme);
  @include smartico-bar($theme);
  @include casino-navbar($theme);
  @include inputs($theme);

  // pages
  @include list-games-page($theme);
  @include content-page($theme);
  @include network-pages($theme);
  @include details-page($theme);
  @include movements-page($theme);
  @include bonuses($theme);
  @include security-page($theme);
  @include responsible-gaming($theme);
  @include deposit-page($theme);
  @include account-verification($theme);
  @include reports-pages($theme);
  @include promotions($theme);
  @include promotion-details($theme);
  @include cashback($theme);
  @include faq($theme);
  @include loyalty-system($theme);
  @include page-play-game($theme);
  //@include live-page($theme);
  @include page-register($theme);
  //@include sportsbook-main($theme);
  @include home-games($theme);
  @include buttons($theme);
  @include affiliate-page($theme);
  // customs at the bottom so it overrides everything
  @include customs($theme);
}

body {
  @include theme-core($theme-variables);
}
