@mixin card($theme) {
  $is-dark: map-get($theme, is-dark);
  $main: map-get($theme, main);
  $primary: map-get($theme, primary);
  $radius: map-get($theme, radius);
  $sidebar-bg: map-get($theme, sidebar-background);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $empty-item: if($is-dark, 50%, 0);

  .mat-mdc-card {
    box-shadow: none;
    border-radius: $radius;
    padding: 0;

    &-content {
      font-size: 1rem;
      padding: 0;
    }

    &.promo-card {
      padding: 8px;

      @include small-desktop {
        padding: 16px;
      }

      .mat-card-image {
        border-radius: $radius;
        margin: 0 0 10px;
        width: 100%;
      }

      .mat-mdc-card-content {
        color: $main;

        &:not(:has(.mat-accordion)) {
          height: 110px;
        }

        .promo {
          &-description {
            color: $text-color;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          &-title {
            color: $primary;
            font-size: 1.25rem;
          }
        }
      }
    }

    &.me-card {
      .mat-mdc-card-title {
        margin-bottom: 0;
        padding: 10px 15px;

        .title {
          color: $text-color;
          font-size: 1.25rem;
          font-weight: 700;
        }
      }
    }

    &.sport-card {
      .mat-mdc-card {
        border-radius: $radius $radius 0 0;

        &-title {
          background-color: $sidebar-bg;
          color: $text-on-primary;
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 0;
          padding: 10px;
          text-transform: uppercase;

          .card {
            &-icon {
              margin-right: 4px;
            }
          }

          .filter-btn {
            background-color: transparent;
            color: $text-on-primary;
            font-size: 1.25rem;
            height: 22px;
            padding: 0;
            width: 24px;

            &-active {
              color: $primary;
            }
          }
        }

        &-content {
          padding: 0;

          .empty {
            &-img {
              filter: invert($empty-item);
              height: 90px;
              width: 90px;
            }

            &-icon {
              color: darken($text-on-primary, 50%);
              font-size: 90px;
              line-height: 1;
            }

            &-text {
              //font-size: $font-size;
            }
          }
        }
      }
    }

    @include small-desktop {
      &.new-card {
        .mat-mdc-card-header {
          .mat-mdc-card-title {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .themes-card{
    aspect-ratio: 1.5/1;
    background: $text-on-primary;
    border-radius: $radius;
    max-width: 100%;

    &-title{
      text-transform: capitalize;
    }
  }
}
