@mixin alerts($theme) {
  $error: map-get($theme, error);
  $radius: map-get($theme, radius);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);

  .alert {
    margin-top: 6px;

    &-info,
    &-secondary,
    &-danger,
    &-warning {
      border-radius: $radius - 4px;
      color: $primary;
      padding: 10px;
      line-height: 1;
    }

    &-danger,
    &-warning {
      background-color: lighten($error, 40%);
    }

    &-info,
    &-secondary {
      background-color: lighten($background, 2%);
    }
  }

  form-builder .mat-error .alert {
    bottom: 0 !important;
    margin-top: -15px;
    position: relative !important;
  }
}
