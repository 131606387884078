mobile-prefix {
  .mobile-prefix-container {
    display: flex;

    .prefix-field {
      margin-right: 6px;

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
        align-items: center;
        display: flex;
        max-width: 122px;
        width: 100%;

        .flag {
          flex-basis: 100%;
          position: relative;
        }

        .mat-mdc-select-value-text {
          padding-inline: .2em;
        }
      }
    }
  }
}
