@mixin loyalty-system($theme) {
  $text-on-primary: map-get($theme, text-on-primary);
  $background: map-get($theme, background);
  $tertiary: map-get($theme, tertiary);
  $text-color: map-get($theme, text-color);
  $success: map-get($theme, success);
  $warning: map-get($theme, warning);

  .loyalty-system {
    .levels {
      &-banner {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        &-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $text-on-primary;

          @media #{$mat-lt-sm}{
            position: relative;
            color: $text-color;
            top: 0;
            left: 0;
            transform: translate(0, 0);
          }
        }
      }

      &-progress {
        background: $text-on-primary;
        border-radius: 8px;

        &-icon {
          height: 22px;
          position: relative;
          
          &.disabled{
            filter: grayscale(100%) opacity(100%) brightness(.5);
          }
        }

        &-light {
          height: 50px;
          position: absolute;
          top: 0;
        }

        mat-progress-bar {
          height: 15px;

          .mat-progress-bar-primary::after {
            background: $tertiary;
          }
        }

        mat-divider {
          margin-inline: -1rem;
        }

        .rakeback-card {
          background: $background;
          border-radius: 8px;

          span {
            font-size: 20px;
          }

          &:first-of-type {
            .mdi::before {
              color: silver;
            }
          }

          &:nth-of-type(2) {
            .mdi::before {
              color: goldenrod;
            }
          }

          &:nth-of-type(3) {
            .mdi::before {
              color: skyblue;
            }
          }
        }
      }

      &-card {
        background: $text-on-primary;
        border-radius: 8px;
        position: relative;

        .rank-img {
          //transform: translateY(-50%);
          height: 70px;
        }

        .light {
          overflow: hidden;
          height: 100%;
          width: 100%;
          position: absolute;

          img {
            display: block;
            height: 280px;
            width: 100%;
            position: absolute;
          }
        }

        .bonus-reward {
          background: $background;
          border-radius: 4px;

          strong {
            font-size: 12px;
          }

          .price {
            font-size: 16px;
          }

          img {
            height: 15px;
          }
        }

        .points {
          color: $success;
          font-size: 1.5rem;
        }

        .text-success {
          color: $success;
        }

        .rank-infos-disabled {
          color: darken($text-on-primary, 50%) !important;
        }

        .silver {
          .mdi-star::before {
            color: #5B7594;
          }

        }

        .gold {
          .mdi-star::before {
            color: #FFAD1E;
          }
        }

        .platinum {
          .mdi-star::before {
            color: #2AADC4;
          }
        }

        .diamond {
          .mdi-star::before {
            color: #CC051C;
          }
        }

      }

      &-header {
        h2 {
          font-size: 30px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    swiper-slide {
      mat-progress-bar {
        border-radius: 0;
        margin-right: 100%;
      }

      &:first-of-type {
        mat-progress-bar {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          width: 10%;
          margin-right: 10px;

        }
      }

      &:last-of-type {
        mat-progress-bar {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;

        }
      }
    }
  }

}
