@mixin movements-page($theme) {
  section.movements {
    .mat-card {
      .mat-card-content {
        .p-2 {
          display: none;
        }
      }
    }
  }
}
