@mixin wallets-viewer($theme) {
  $primary: map-get($theme, primary);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $icon: map-get($theme, icon);

  .wallets-wrapper {
    .mat-card.fancy-card {
      margin: 5px;

      .mat-card-header-text {
        color: $text-color;
        font-weight: 600;
        margin: 0;
        width: 100%;

        .mat-card-title {
          border-bottom: 2px solid $primary;
          //font-size: $font-size + 5;
          margin-bottom: 0;
          padding: 15px;
        }

        .mat-card-subtitle {
          color: $primary;
          //font-size: $font-size + 1;
          margin: 0;
          padding: 15px;

          md-icon {
            padding-right: 4px;
          }
        }
      }
    }

    @include mobile {
      .col-4 {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
}
