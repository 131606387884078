@mixin casino-navbar($theme) {
  .casino-navbar {
    margin: 0 -1rem;

    .nav-link {
      svg {
        filter: opacity(50%);
      }

      &.active {
        svg {
          filter: none;
        }
      }
    }
  }
  @media screen and (min-width: 1440px) {
    .casino-navbar {
      margin: 1rem;
    }
  }
}

