*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

html {
  box-sizing: border-box;
  font-size: var(--font-size);
  scroll-behavior: smooth;

  @supports (min-height: -webkit-fill-available) {
    min-height: -webkit-fill-available;
  }
}

body {
  background-color: var(--background);
  box-sizing: border-box;
  min-height: 100vh;

  @supports (min-height: -webkit-fill-available) {
    min-height: -webkit-fill-available;
  }
}

.container-wrapper {
  margin: 0 auto;
  min-height: 100vh;
  max-width: var(--grid-width);
  padding-inline: 0;
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

.container {
  padding-inline: 0;
}
