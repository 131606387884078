@mixin details-page($theme) {
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);

  /*.me-security,
  .me-details {*/
  .details-container {
    .mat-mdc-card {
      .mat-mdc-tab-group {
        .mat-mdc-tab-header {
          .mat-mdc-tab {
            color: $text-color;
            font-weight: 600;

            @include small-desktop {
              //font-size: $font-size;
            }

            &-active {
              background: transparent;
              color: $text-color;
            }
          }
        }

        .mat-mdc-tab-body-wrapper {
          padding: 25px 20px 15px;

          @include mobile {
            padding: 25px 0 20px;
          }

          .mat-mdc-tab-body-wrapper {
            padding: 0;
          }

          .mat-mdc-form-field {
            margin-bottom: 20px;
            padding: 0;

            &-infix {
              input {
                font-weight: 500;
              }

              label {
                text-transform: capitalize;
              }
            }
          }

          geo-infos {
            .mat-mdc-tab-body-content {
              .row.m-0 {
                .col-12 {
                  flex-basis: 50%;
                  max-width: 50%;
                }
              }

              .alert {
                margin: 10px 0 !important;
              }
            }
          }
        }
      }

      form-builder {
        margin: 0 !important;
        margin-top: 20px !important;

        form {
          margin: 0;

          .p-field:last-of-type {
            @include tablet {
              padding: 0;
            }
          }
        }

        birth-place {
          display: flex;
          flex-wrap: wrap;

          .row.g-2{
           margin-inline: 0;
          } 
        }

        .mat-mdc-radio-button{
          margin-right: 10px;
        }

        @include mobile{
          .mat-mdc-form-field{
            margin-bottom: 0 !important;
          }

          field birth-place .row{
            padding-inline: 0 !important;
          }
        }
      }

      .mat-radio-group {
        margin-left: 5px;
      }

      @include mobile {
        .mat-tab-label {
          min-width: fit-content !important;
        }
      }
    }
  }
  //}
}
