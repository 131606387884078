@mixin buttons($theme) {
  .mdc-button {
    font-size: 1rem;
    align-items: center;
    display: flex;
    //font-weight: 600 !important;
    //height: 38px;
    min-width: auto;
    justify-content: center;
    text-transform: uppercase;
    white-space: nowrap;

    .mdc-button__label {
      align-items: center;
      display: flex;
    }
  }

}
