@mixin autoexclusion-dialog($theme) {
  $font-size: map-get($theme, font-size);

  .dialog-auto-exclusion{
    .max-vh {
      height: auto;
    }

    font-size: $font-size + 1;

    .mat-card{
      margin-bottom: 0;

      .mat-card-title{
        font-size: inherit;
        font-weight: 600;
        padding: 0 10px;

        .mdi-account-off{
          display: none;
        }
      }

      .auto-exclusion-wrapper{
        .dialog-body{
          min-height: 180px;
          display: flex;
          justify-content: center;
          align-items: center;

          div{
            margin-bottom: 30px !important;
          }

          button{
            bottom: 0;
            left: 0;
            margin: 15px;
            position: absolute;
            right: 0;
          }
        }
      }

      @include small-desktop {
        width: 400px ;
      }

      @include mobile {
        .auto-exclusion-wrapper{
          height: calc(100vh - 81px);

          .dialog-body{
            height: 100%;
          }
        }
      }
    }
  }
}