@mixin faq($theme) {
    $border-color: map-get($theme, border-color);
    $radius: map-get($theme, radius);
    $primary: map-get($theme, primary);
    $text-on-primary: map-get($theme, text-on-primary);

    app-faq{
        .faq-card{
            background: $text-on-primary;
            border: 1px solid $border-color;
            border-radius: $radius;
            height: 200px;

            svg{
                color: $primary;
            }
        }

        mat-expansion-panel{
           box-sizing: border-box;

           @include mobile {
                h3{
                    font-size: 1.2rem;
                }
            }
        }
    }
}
