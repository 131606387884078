@mixin dialogs($theme) {
  $main: map-get($theme, main);
  $sidebar-background: map-get($theme, sidebar-background);
  $border-color: map-get($theme, border-color);
  $radius: map-get($theme, radius);
  $font-size: map-get($theme, font-size);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $bg-dialog:  $text-on-primary;
  $thead-background: map-get($theme, grey-100);

  .mat-mdc-dialog-container {
    background-color: $bg-dialog;
    border-radius: $radius;
    max-height: 100vh;

    .mat-mdc-card {
      box-shadow: none;
      margin: 10px 10px;

      &-title {
        align-items: center;
        color: $text-color;
        display: flex;
        font-size: $font-size + 5;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 0 20px;
      }
    }

    .mat-dialog-title {
      color: $main;
      font-size: $font-size;
      padding: 0 20px;
    }

    .mat-dialog-content {
      margin: 0;
      padding: .6rem 20px 0;
    }
  }

  @media #{$mat-lt-sm} {
    .cdk-overlay-pane {
      background-color: $sidebar-background;
      max-width: 100vw !important;

      .mat-mdc-dialog-container {
        border-radius: 0;
        height: calc(100svh - 44px);
        max-width: 100vw;
        margin-top: 44px;
        width: 100vw;

        app-dialog-settings {
          .close-icon {
            @include close-icon-on-mobile();
          }
        }

        .mat-mdc-card-title {
          .close-icon {
            @include close-icon-on-mobile();
          }
        }
      }
    }
  }

  @include tablet {
    .mat-mdc-dialog-container {
      .close-icon {
        display: none;
      }
    }
  }

  .games-filter-dropdown {
    .mat-mdc-dialog-container {
      width: 360px;

      .top-side {
        padding: 8px 16px;
      }

      .popular-btn,
      .sort-icon {
        font-size: $font-size + 7;
      }

      .form-filter {
        .form-wrapper {
          height: 300px;
          padding-left: 10px;
          padding-right: 10px;
          overflow-y: scroll;
        }

        .buttons-wrapper {
          border-top: 1px solid $border-color;
          padding: 8px 16px 0;
        }

        .mat-checkbox {
          align-items: center;
          border-radius: $radius;
          display: flex;
          height: 48px;
          width: 100%;

          .float-label {
            font-size: $font-size - 2;
            font-weight: 700;
            left: 16px;
            position: absolute;
            top: 5px;
          }

          &.top-provider {
            background-color: grey;
            height: 64px;

            .mat-checkbox-layout {
              padding-top: 10px;
            }
          }

          &-layout {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
          }

          &-label {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: space-between;
          }
        }
      }
    }
  }

  :where(wallets-movement-details-dialog, ticket-details-dialog) {
    .mat-mdc-card {
      &-title {
        md-icon.pr-1 {
          display: none;
        }
      }
    }
  }

  wallets-movement-details-dialog {
    .dialog-wrapper {
      margin-top: 15px;

      thead {
        background-color: $thead-background;

        .mat-row,
        .mat-header-cell {
          background-color: inherit;
        }

        .mat-row {
          height: 34px;
        }
      }
    }
  }
}

@mixin close-icon-on-mobile($text-on-primary: #FFF) {
  background-color: transparent;
  border: none;
  color: $text-on-primary;
  display: inline;
  font-size: 18px;
  padding: 0;
  position: fixed;
  right: 13px;
  top: 10px;

  md-icon {
    line-height: 1;
  }
}
