@mixin content-page($theme) {
  $border-color: map-get($theme, border-color);
  $text-color: map-get($theme, text-color);
  $primary: map-get($theme, primary);

  app-pages {
    .container-fluid {
        .content{
            ol, ul{
                padding-left: 15px;
            }
        }

      .mat-mdc-card {
        box-shadow: none;

        &-title {
          border-bottom: 1px solid $border-color;
          height: auto !important;
        }

        &-content {
          font-size: 1.25rem;
        }
      }

      .mat-expansion-panel {
        border-radius: 8px !important;

        &-body {
          padding: 0;
        }

        &-header {
          height: 36px;

          &-title {
            color: $text-color;
            //font-size: 13px;
            font-weight: 700;
          }
        }
      }

      .nav-item {
        .nav-link {
          padding: 10px 15px;

          &.active {
            background-color: rgba(0, 0, 0, 0.05);
            color: $primary;
          }

          &:hover:not(.active) {
            // background-color: rgba(0, 0, 0, 0.05);
            color: $primary;
          }
        }

        &:first-of-type {
          border-top: 1px solid $primary;
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }
}
