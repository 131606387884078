@mixin inputs($theme) {
  .pin-input {
    border: 1px solid #F0F0F0;
    border-radius: var(--radius);
    color: var(--text);
    font-size: 1.3rem;
    height: 52px;
    line-height: 1;
    margin-right: 5px;
    text-align: center;
    width: 100%;
  
    &:focus {
      border: 1px solid var(--primary);
    }
  
    &.ng-invalid.ng-touched {
      border-color: #E3321A;
    }
  }
  
}