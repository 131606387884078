$mat-xs: 'screen and (max-width: 767px)';
$mat-sm: 'screen and (min-width: 768px) and (max-width: 991px)';
$mat-md: 'screen and (min-width: 960px) and (max-width: 1279px)';
$mat-lg: 'screen and (min-width: 1280px) and (max-width: 1919px)';
$mat-xl: 'screen and (min-width: 1920px) and (max-width: 5000px)';
$mat-lt-xs: 'screen and (max-width: 576px)';
$mat-lt-sm: 'screen and (max-width: 767px)';
$mat-lt-md: 'screen and (max-width: 991px)';
$mat-lt-l: 'screen and (max-width: 1024px)';
$mat-lt-lg: 'screen and (max-width: 1279px)';
$mat-lt-xl: 'screen and (max-width: 1919px)';
$mat-gt-xs: 'screen and (min-width: 768px)';
$mat-gt-sm: 'screen and (min-width: 992px)';
$mat-gt-l: 'screen and (min-width: 1024px)';
$mat-gt-md: 'screen and (min-width: 1280px)';
$mat-gt-l: 'screen and (min-width: 1366px)';
$mat-gt-xl: 'screen and (min-width: 1920px)';

$success-color: #5AA51A;
$warning-color: #F9C22A;
$danger-color: #FA461C;
$info-color: #0C8BB9;
$error-color: #900;

@media #{$mat-lt-md} {
  #launcher {
    display: none;
  }
}

@mixin mobile {
  @media #{$mat-lt-sm} {
    @content;
  }
}

@mixin tablet {
  @media #{$mat-gt-xs} {
    @content;
  }
}

@mixin small-desktop {
  @media #{$mat-gt-sm} {
    @content;
  }
}

@mixin desktop {
  @media #{$mat-gt-md} {
    @content;
  }
}

@mixin orientation-landscape {
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    @content;
  }
}

.mobile-only {
  @include tablet {
    display: none;
  }
}

.tablet-only {
  @include mobile {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.img-fluid {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.content-wrapper {
  background-color: var(--primary);
  color: var(--white-color);
  font-size: 1.3rem;
  padding: 12px 25px;
}

.max-vh {
  height: calc(100vh - 68px);
  overflow-y: auto;

  //@include scrollbar-desktop();
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading-overlay {
  background-color: rgba(0, 0, 0, .1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  mat-spinner {
    left: calc(50% - 22px);
    position: absolute;
    top: calc(50% - 22px);
  }
}
////////////////////////////////////////////////////////////////////////////////////////////

.main-grid-system {
  --grid-col-width: 85px;
  --grid-col-width-expand: 220px;
  --padding-spacer: 30px;
  display: grid;
  grid-template-columns: var(--grid-col-width) 1fr;
  transition: .3s ease;

  .max-vw {
    width: calc(100vw - (var(--grid-col-width) + var(--padding-spacer)));
  }

  &.expanded {
    grid-template-columns: var(--grid-col-width-expand) 1fr;

    .max-vw {
      width: calc(100vw - (var(--grid-col-width-expand) + var(--padding-spacer)));
    }
  }

  .sportsbook-grid {
    display: grid;
    gap: 10px;

    @media #{$mat-gt-l} {
      grid-template-columns: minmax(700px, 1fr) 280px;
    }
  }
}

.title {
  font-size: 1.25rem;
}

.subtitle {
  font-size: 1rem;
}

.sportsbook-title {
  font-size: 1.25rem;
  line-height: 38px;
}

.flag-rounded {
  border-radius: 50%;
  overflow: hidden;
  height: 16px;
  width: 16px;
}

.min-vh {
  min-height: calc(100vh - 70px);
}

// Padding
.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-t-0 {
  padding-top: 0;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-0 {
  padding-bottom: 0;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-30 {
  padding-bottom: 30px;
}

// Margin
.m-0 {
  margin: 0;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.bordered {
  border: 1px solid map-get($theme-variables, border-color);
}

//SVG
//--------------------------
.fill-current {
  fill: currentcolor !important;
}

@include mobile {
  .container {
    padding-inline: 15px;
  }

  .me-container {
    .container {
      padding-inline: 0;
    }
  }
}

@mixin wrapper-container($width: 420px) {
  margin: 0 auto;
  max-width: $width;
  width: 100%;
  padding: 0 15px;

  @include tablet {
    padding: 0;
  }
}

@mixin descriptionText($main) {
  color: $main;
  //font-size: 13px;
  line-height: 20px;
}

pre {
  font: 1rem monospace;

  &.floating {
    background-color: #FFF;
    border-radius: 5px;
    bottom: 10px;
    box-shadow: 1px 1px 2px #111;
    color: #000;
    max-width: 30vw;
    overflow-y: auto;
    padding: 10px;
    position: fixed;
    right: 10px;
    text-overflow: ellipsis;
    top: 10px;
    z-index: 100;

    &.left {
      right: auto;
      left: 10px;
    }
  }
}

.position-relative {
  position: relative;
}


iframe {
  border: none;
}

.mat-divider {
  color: transparent;
  height: 2px;
  background-image: linear-gradient(to right, rgb(82, 82, 82) 48.96%, rgba(16, 20, 25, 0) 100%);
}
