@mixin footer($theme) {
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $primary: map-get($map: $theme, $key: primary);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $light-gray: darken($text-on-primary, 8%);

  .footer-container {
    background: $text-on-primary;
    line-height: 1.5;
    padding-top: 20px;

    svg{
      color: $primary;
    }

    .footer-links {
      margin-bottom: 10px !important;
    }

    .mat-expansion-panel {
      box-shadow: none;
      border: 1px solid $light-gray;
      border-radius: 8px;

      &:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
        background: $light-gray !important;
      }

      &-header {
        background: $light-gray;
        height: 40px;

        &.mat-expanded {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          background: $light-gray;
          height: 40px;
        }
      }

      &-body {
        padding-top: 10px;
      }

      li:not(:last-of-type) {
        padding-bottom: 5px;
      }
    }

    &.mobile {
      padding-bottom: 50px;

      .disclaimer-container {
        max-width: 90%;
      }
    }

    &:not(.mobile) {
      .disclaimer-container {
        max-width: 40%;

        span {
          display: block;
          text-align: justify;
        }
      }
    }

    license {
      margin-top: 30px;

      .license-area {
        margin-top: 5px;
      }
    }

    .license-area {
      text-align: center;

      .license-img {
        margin-bottom: 10px;
      }

      .img-fluid {
        max-height: 24px;
      }

      .plus-18 {
        background-color: #F44336;
        border-radius: 50%;
        font-family: sans-serif;
        font-size: 0.6rem;
        font-weight: bold;
        height: 20px;
        line-height: 1;
        padding: 5px 0;
        width: 20px;
      }
    }

    .licensee-logo-wrapper {
      background-position: center center;
      background-repeat: no-repeat;
      height: 30px;
      text-align: center;
      width: 100%;
    }

    .logo-container {
      padding: 15px 0;
      width: 200px;

      .bck {
        background-position: center;
        background-repeat: no-repeat;
        height: 35px;
      }
    }

    ul {
      list-style: none;

      li {
        padding: 3px 0;

        &:hover {
          a {
            color: $primary;
          }
        }

        a.active {
          color: $primary;
        }

        md-icon {
          display: none;
        }
      }
    }

    .links-container {
      justify-content: space-evenly;

    }

    .disclaimer-container {

      span {
        line-height: 1.5;

        a {
          color: $tertiary;
        }
      }
    }

    .go-up-container {
      display: none;
    }

    .socials-container {
      display: none !important;
    }

    .links-container,
    .payments-container,
    .operators-container {
      margin-top: 30px;

      .logo-wrapper {
        display: flex;
        flex: 0 0 65px;
        justify-content: center;
        margin-bottom: 15px;
      }
    }
  }

  .payments-container {
    max-width: 1100px;
    margin: auto;

    .logo-wrapper {
      background-color: darken($text-on-primary, 8%);
      border-radius: 5px;
      margin: 4px;
      padding: 3px 12px;
    }
  }

  .logo-operator,
  .logo-social,
  .logo-payment {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    height: 33px;
    width: 100%;

    a {
      background-color: transparent;
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .logo-wrapper {
    .bs-logo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 25px;
      width: 25px;
    }
  }
}
