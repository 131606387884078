@mixin smartico-bar($theme) {
  $text-on-primary: map-get($theme, text-on-primary);
  $primary: map-get($theme, primary);

  smartico-bar {
    padding-inline: 10px;

    @media #{$mat-lt-l} {
      display: block;
    }

    .smartico-container {
      button {
        padding-inline: 15px !important;
        background: none !important;
        border: 1px solid $primary;

        @media (min-width: 1024px) and (max-width: 1240px) {
          padding-inline: 5px !important;
        }
      }

      @media #{$mat-lt-l} {
        overflow-x: auto;
        padding: 5px 10px;

        button {
          background: none !important;
          border: none !important;
          padding-inline: 5px !important;

          &:hover {
            background: none !important;
          }
        }
      }

      deep-link {
        margin-left: 5px;

        .props {
          font-size: 12px;
        }

        button {
          height: 100%;
        }
      }
    }
  }
}
