@mixin mobile-tickets($theme) {
  $background: map-get($map: $theme, $key: background);
  $border-color: map-get($map: $theme, $key: border-color);
  $text-color: map-get($map: $theme, $key: text-color);
  $panel-background: lighten($background, 100%);
  $status-background: lighten($background, 0%);
  $success: map-get($map: $theme, $key: success);
  $text-on-success: map-get($map: $theme, $key: text-on-success);
  $danger: map-get($map: $theme, $key: danger);
  $text-on-danger: map-get($map: $theme, $key: text-on-danger);

  .mobile-tickets-container {
    display: flex;
    flex-direction: column;

    .ticket-entry {
      display: flex;
      background: $panel-background;
      border-block: .5px solid $border-color;
      color: $text-color;
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: 10px;
      overflow: hidden;
      padding: 5px;
      position: relative;

      .side-status {
        align-items: center;
        background: $status-background;
        display: flex;
        font-weight: bold;
        justify-content: center;
        text-orientation: mixed;
        text-transform: uppercase;
        width: 25px;
        writing-mode: vertical-rl;
      }

      .ticket-body {
        padding-block: 5px;
        width: 100%;

        .col-6 {
          max-width: 100%;
          display: flex;
          justify-content: space-between;
          padding-inline: 5px;
          width: 100%;

          span {
            font-weight: 700;
          }

          b {
            font-weight: 400;

            span {
              font-weight: 400;
            }
          }
        }

        span {
          display: flex;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .sport-title {
            display: inline-block;
            max-width: calc(100% - 2px);
          }
        }
      }

      &.won {
        border-color: $success;

        .side-status {
          background: $success;
          color: $text-on-success;
        }
      }

      &.lost {
        border-color: $danger;

        .side-status {
          background: $danger;
          color: $text-on-danger;
        }
      }

      &.no-status {
        padding: 0;

        .side-status {
          display: none;
        }
      }
    }
  }
}
