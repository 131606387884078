@mixin affiliate-page($theme) {
  $primary: map-get($theme, primary);
  $warning: map-get($theme, warning);
  $text-on-primary: map-get($theme, text-on-primary);

  .affiliate {
    .icon-gold {
      color: $warning;
    }

    .commissions {
      &-title {
        color: $primary;
      }

      &-description {
        font-size: 2rem;
      }
    }

    .mat-expansion-panel {
      --mat-expansion-header-hover-state-layer-color: $text-on-primary;
    }

    .navbar {
      &-nav {
        gap: 10px 12px;
        list-style: none;

        .nav-item {
          transition: color .2s ease;

          .nav-link {
            cursor: pointer;
          }

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .new-card {
      transform: scaleX(1.00) scaleY(1.00) rotate(0deg);
      transition: .2s ease;

      &:hover {
        transform: scaleX(1.02) scaleY(1.02) rotate(1deg);
      }
    }
  }
}
