@mixin home-games($theme) {
  $background: map-get($theme, background);

  .home-games {
    .title {
      font-size: 25px;
    }

    .smartico {
      &-promo {
        max-width: 1450px;
        position: relative;

        &-img {
          height: 550px;
          position: absolute;
          right: 30px;
          top: -45px;
          z-index: 10;

          @media (max-width: 1200px) {
            height: auto;
            margin: auto;
            position: relative;
            top: 0;
            width: calc(100vw - 600px);

            @media #{$mat-lt-sm}{
              width: 80vw;
            }
          }
        }

        p {
          font-size: 16px;
        }
      }

      &-card {
        width: 240px;

        @media (max-width: 1200px) {
          background: $background;
          width: auto;
        }

        h4 {
          font-size: 20px;
        }
      }

      &-header {
        width: 350px;

        @media (max-width: 1200px) {
          text-align: center;
          width: auto;
        }

        h3 {
          font-size: 30px;
        }
      }

      &-cards {
        @media (min-width: 1200px) and (max-width: 1300px) {
          margin-top: 30px;

          .smartico-card {
            width: 200px;

            h4 {
              font-size: 18px;
            }

            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
