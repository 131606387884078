@mixin forgot-password-dialog($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $grey: map-get($theme, grey);
  $font-size: map-get($theme, font-size);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $autofill-background: if($is-dark, darken($grey, 9%), $text-on-primary);

  forgot-password-dialog {
    .dialog {
      .mat-mdc-card {
        background-color: transparent;
        padding: 16px;
        margin: 0;

        .mat-mdc-card-title {
          font-size: $font-size + 9;
          font-weight: 700;
          margin-bottom: 10px;

          @media #{$mat-lt-l} {
            padding: 0;
          }

          div svg,
          svg.me-1 {
            display: none;
          }

          .close-icon{
            --mdc-filled-button-container-color: transparent;
            --mat-filled-button-state-layer-color: transparent;
            height: 24px;
          }
        }

        .mat-mdc-tab-group {
          &-body{
            @media #{$mat-lt-l} {
              height: calc(100svh - 96px);
            }
          }
        }

        .mat-mdc-tab-header {
          display: none;
        }

        .mat-mdc-tab-body-content {
          overflow: hidden;

          > *:first-child {
            margin: 0 1rem !important;
            padding-bottom: 6px;
          }

          .forgot-password-message,
          mat-tab-header,
          svg {
            display: none;
          }

          input:-webkit-autofill {
            box-shadow: 0 0 0 1000px $autofill-background inset;
          }

          .button-container {
            margin-top: 10px;

            @media #{$mat-lt-l} {
              bottom: 20px;
              left: 0;
              padding: 0;
              position: absolute;
              right: 0;
            }
          }

          form  {
            .p-field{
              @media #{$mat-lt-l} {
                padding: 0;
              }
            }

            button{
              font-weight: 600;
              height: 38px;
              line-height: 38px;
              padding: 0;
              text-transform: capitalize;
              width: 100%;
            }
          }
        }
      }
    }

    @media #{$mat-lt-l} {
      *:not(.mat-mdc-card-title):not(.button-container):not(.p-field) {
        height: 100%;
      }
    }
  }
}
