@mixin deliveries($theme) {
  $border-color: map-get($theme, border-color);
  $grey-500: map-get($theme, grey-500);
  $radius: map-get($theme, radius);
  $success: map-get($theme, success);
  $primary: map-get($theme, primary);
  $text-on-primary: map-get($theme, text-on-primary);
  $error: map-get($theme, error);

  .deliveries {
    &-box {
      border: 1px solid $border-color;
      border-radius: $radius;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
      padding: 0 20px;

      :is(.back-btn, .remove-btn) {
        border-color: $primary;
      }

      &-icon {
        color: $grey-500;
        font-size: 22px;
      }

      &-text {
        text-transform: uppercase;
      }

      .mat-checkbox{
        margin-top: 15px;
        margin-left: 5px;
      }

      pin-input {
        .text-center {
          text-align: left;
        }

        form.d-flex {
          > div {
            flex: 1;

            &:not(:last-child) {
              margin-right: 16px
            }

            .pin-input {
              border-radius: $radius;
              max-height: 56px;
              max-width: 100%;
              width: 100%;
            }
          }
        }
      }

      @include mobile {
        &:last-child{
          .row{
            .col-11{
              flex-basis: 80%;
              max-width: 85%;
            }
          }
        }
      }
    }

    &-check-icon {
      color: $success;
      font-size: 26px;
    }

    &-not-confirmed {
      color: $error;

      md-icon {
        font-size: 18px;
      }

      & + .d-flex .mat-button-base {
        padding-inline: 12px;

        &.mat-flat-button {
          color: $text-on-primary;
        }
      }
    }

    // Hiding mobile activation
    // Component must be changed

    &-wrapper{
      .col-lg-6{
        &:first-of-type{
          display: none;
        }

        &:nth-child(2){
          flex-basis: 100% !important;
          max-width: 100% !important;
        }
      }
    }

    &-check-box{
      .col-12{
        &:first-of-type:not(:only-child){
          display: none;
        }
      }
      .mat-form-field{
        margin: 0 !important;
      }

      .alert{
        margin-top: 6px !important;
      }

      .send-activation-btn{
        font-weight: 600;
      }
    }
  }
}
