@mixin page-play-game($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $font-size: map-get($theme, font-size);
  $primary: map-get($theme, primary);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $border-color: map-get($theme, border-color);
  $radius: map-get($theme, radius);

  .play-game {
    &-grid {
      display: grid;
      gap: 0 20px;
    }

    .casino-tournament-wrapper {
      .mat-tab-body {
        padding: 10px;

        &-content {
          background-color: transparent;
        }
      }

      .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header {
        border: 1px solid $border-color !important;
      }

      .games-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

        & + .load-more .mat-flat-button {
          background-color: $primary !important;
        }
      }
    }

    &-wrapper {

      .title {
        font-size: $font-size + 3;
      }

      .mat-card {
        padding: 16px;

        .icon {
          font-size: $font-size + 7;
        }

        .mat-icon-button {
          width: auto;

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }

      .mat-card-header {
        &-text {
          display: none;
        }
      }

      .real-link {
        border-radius: 25px;
        border-color: $primary;
      }

      .favourite-btn {
        .fill .icon {
          color: $primary;
        }
      }
    }

    &-iframe {
      .frame {
        aspect-ratio: 16 / 9;
      }
    }

    &-infos {
      position: relative;

      .mat-mdc-card {
        border-radius: 0;
        display: none;
        margin-left: auto;
        max-width: 0;
        overflow-y: auto;

        &-content {
          padding: 0;
        }

        &.open-sidebar {
          border-top: 1px solid $border-color;
          display: block;
          max-width: 96%;
          min-width: 210px;
          padding: 0;
        }
      }

      .expand-menu {
        --mat-icon-button-state-layer-color: transparent;
        background-color: $text-on-primary;
        border-radius: $radius 0 0 $radius;
        font-size: $font-size + 7;
        padding: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 26px;

        .mat-mdc-button-touch-target {
          width: auto;
        }

        &.open {
          left: -10px;
        }
      }

      .game-info {
        padding: 10px 10px 0;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &:last-child {
          margin-bottom: 10px;
        }
      }

      .favourites-side-box,
      .casino-tournaments-wrapper {
        md-icon {
          color: lighten($text-color, 80%);
          font-size: 100px;
        }

        .favourites-side-title {
          font-size: $font-size + 1;
          font-weight: 700;
        }

        .favourites-side-text {
          font-size: $font-size - 1;
        }
      }
    }

    &-grid {
      &.full-screen {
        .mat-card {
          padding: 16px 0 0;

          &-header {
            padding-inline: 16px;
          }

          &-content {
            background-color: $text-color;
          }
        }

        .play-game-iframe .frame {
          aspect-ratio: 16 / 8;
        }
      }
    }

    @include small-desktop {
      &-grid {
        grid-template-columns: 1fr minmax(0, 1230px) 1fr;

        &.expanded-grid {
          @include small-desktop {
            grid-template-columns: 1fr minmax(0, 1230px) minmax(410px, 1fr);
          }
        }
      }
    }
  }
}
