@mixin data-table-builder($theme) {
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $grey-100: map-get($map: $theme, $key: grey-100);
  $grey-200: map-get($map: $theme, $key: grey-200);
  $search-background:  $text-on-primary;

  data-table-builder {
    .dot {
      border-radius: 50%;
      height: 12px;
      margin-left: 30px;
      width: 12px;

      &.open {
        background-color: lightgray;
      }

      &.won {
        background-color: green;
      }

      &.lost {
        background-color: red;
      }

      &.void,
      &.refunded {
        background-color: yellow;
      }
    }

    .mdc-data-table__row:last-child .mat-mdc-cell {
      border-bottom: var(--mat-table-row-item-outline-width, 1px) solid var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    }

    .mat-table {
      thead {
        background: $grey-100;

        .mat-header-row{
          height: 34px;

          .mat-header-cell{
            color: $text-color;
            font-weight: 400;
          }
        }

        .mat-column-actions {
          padding: 5px 0;
        }
      }

      tbody {
        button:has(.mdi-magnify) {
          background: $search-background;
        }

        .mat-column-actions {
          div:has(button) {
            margin-left: 10px;
          }
        }

        .mat-row {
        height: 44px;

          .mat-cell {
            padding-bottom: 0;
            padding-top: 0;
          }
        }
      }
    }

    .mat-mdc-paginator-outer-container {
      .mat-mdc-paginator-container {
        flex-direction: row-reverse;
        justify-content: space-between;

        .mat-mdc-paginator-page-size {
          align-items: center;

          &-select {
            width: 64px;
          }

          .mdc-text-field--outlined {
            --mdc-outlined-text-field-outline-width: 0;
          }

          .mat-mdc-text-field-wrapper {
            height: auto;
          }

          .mat-form-field-outline {
            border: none;
            color: $search-background !important;
          }
        }

        .mat-mdc-paginator-range-label {
          margin-inline: 20px;
        }
      }
    }
  }
}
