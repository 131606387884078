$theme-variables: (
  font-size: 11px,
  background: #F5F6F7,
  border-color: rgba(0, 0, 0, .1),
  header: #FFF,
  main: #FFF,
  primary: #E50024,
  secondary: #FFDF00,
  tertiary: #002776,
  icon: #888,
  shade: #272727,
  success: #27AE60,
  warning: #EBC063,
  error: #F42323,
  text-on-primary: #FFF,
  text-color: #282828,
  radius: 0px,
  white-color: #FFF,
  sidebar-background: #222222,
  grey-color: #263238,
  grey-700: rgba(67, 80, 83, .7),
  grey-500: rgba(67, 80, 83, .5),
  grey-300: rgba(67, 80, 83, .3),
  grey-200: rgba(67, 80, 83, .2),
  grey-100: rgba(67, 80, 83, .1),
  grey-05: rgba(67, 80, 83, .05),
);

:root {
  @each $key, $value in $theme-variables {
    --#{$key}: #{$value};
  }
}
