@mixin network-pages($theme) {
  $text-on-primary: map-get($theme, text-on-primary);

  .network-players,
  .network-agents,
  .network-shops,
  .network-search,
  .network-transfers,
  .referral {
    .mat-card{
      .mat-card-content{
        
        .mat-button-base:first-of-type:only-of-type{
          color: $text-on-primary;
        }

        .mat-expansion-panel{
          &-body{
            @include mobile{
              padding: 10px;

              date-range{
                padding-inline: 15px;
              }
            }
          }
        }
      }
    }
  }
}
