@mixin sidebar-menu($theme) {
  $primary: map-get($map: $theme, $key: primary);
  $border-radius: map-get($theme, radius);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $text-color: map-get($map: $theme, $key: text-color);
  $border-color: map-get($map: $theme, $key: border-color);
  $sidebar-background: map-get($map: $theme, $key: sidebar-background);

  .left-side {
    background-color: $sidebar-background;

    .sidebar-menu {
      position: sticky;
      top: 0;

      .mdc-icon-button {
        @include mobile-chat-btn();
        border: 2px solid rgba($text-on-primary, .12);
        border-radius: $border-radius;
        color: $text-on-primary;
        font-size: 1.25rem;
      }

      .logo {
        margin: 25px 0;
        width: 160px;
      }

      .logo-small {
        margin: 10px 0;
        width: 50px;
      }

      .title {
        font-size: 1.75rem;
        color: $primary;
        left: 15px;
        position: relative;
      }

      menu {
        ul {
          .nav-link {
            color: $text-on-primary;
            font-size: 1.25rem;
            padding: 9.5px 10px;
            text-transform: capitalize;

            span {
              font-weight: 700;

              &::before {
                font-weight: 400;
              }
            }

            &:hover,
            &.active {
              color: $primary;
            }
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .mat-drawer {
    background: $text-on-primary;
    max-height: 100%;
    width: 100vw;
    z-index: 10;
  }

  .sidebar-menu-mobile {
    .nav-item {
      align-items: center;
      border-bottom: 1px solid $border-color;
      display: flex;
      height: 40px;
      padding-left: 15px;
      text-transform: capitalize;

      .mdc-icon-button {
        @include mobile-chat-btn();
        padding-left: 0;
        width: 100%;
      }

      .item-label {
        &::before {
          margin-right: 10px;
        }
      }

      .nav-link {
        &.active {
          color: $primary;
        }
      }
    }
    dropdown-language.nav-item {
      padding-left: 0;
      --mdc-outlined-text-field-outline-width: 0;

      .mdc-floating-label {
        display: none;
      }
    }
  }
}

@mixin mobile-chat-btn() {
    font-family: inherit;
    --mdc-icon-button-state-layer-size: 100%;
    --mat-icon-button-state-layer-color: transparent;
    --mat-icon-button-ripple-color: transparent;

    span {
      font-size: 13px;
    }
}
