@mixin tree-table($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $border-color: map-get($map: $theme, $key: border-color);
  $text-color: map-get($map: $theme, $key: text-color);
  $tertiary: map-get($map: $theme, $key: tertiary);
  $font-size: map-get($map: $theme, $key: font-size);
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba(#000, .2));
  $table-bg: if($is-dark, lighten($background, 8%), $background);
  $table-header-bg: if($is-dark, lighten($background, 10%), darken($background, 5%));
  $panel-header-height: 35px;
  $radius: map-get($map: $theme, $key: radius);

  .tree-table {
    background: $table-bg;
    border-radius: $radius;
    box-shadow: $shadow-small;
    font-size: $font-size;
    overflow: visible;
    width: 100%;

    th,
    td {
      border-color: $border-color;
      border-style: solid;
      border-width: 1px;
      color: $text-color;
      padding: 10px;


      &:not(:last-child) {
        border-right: none;
      }
    }

    thead {
      background: $table-header-bg;
      border-bottom: 2px solid $tertiary;

      tr {
        font-size: $font-size;
        font-weight: bold;
        height: $panel-header-height;

        &:first-child th:first-child {
          padding-left: 15px;
        }
      }
    }

    tbody {
      tr {

        td {
          &:first-child {
            padding-left: 15px;
          }
        }
      }
    }
  }

}
