
@mixin loyalty-levels-dialog($theme) {
    $background: map-get($theme, background);
    $success: map-get($theme, success);
    $border: map-get($theme, border-color);

    loyalty-levels-dialog{
        .mat-mdc-card-title.box-header strong{
            font-size: 20px;
        }
        .mat-mdc-card .mat-mdc-card{
            background: $background;
            border-radius: 8px;

            .rank-img{
                height: 18px;
            }

            .border{
                border-right: 1px solid $border;
            }

            &-title{
                margin-top: 0 !important;

                .points{
                    color: lighten($success , 20%);
                }

                span{
                    text-transform: capitalize;
                    font-size: 14px;
                }
            }
        }
    }

    .loyalty-dialog{
        .mat-mdc-dialog-container{
            @media #{$mat-gt-sm} {
                width: 450px;
            }
        }
    }
}
