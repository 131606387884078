@mixin page-register($theme) {

  .register {
    .mat-horizontal-content-container {
      padding: 25px;

      .reg-buttons-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
