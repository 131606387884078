@mixin reports-pages($theme) {

  .reports{
    .report-cash-flow,
    &-network-casino,
    &-network-lottery,
    &-network-finance,
    &-network-poker,
    &-network-betting,
    &-network-virtual,
    &-network-casinolive{
      .mat-expansion-panel{
        &-body{
          padding: 10px;
        }
      }
  
      .options-container {
        mat-form-field {
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
}
