@mixin register-dialog($theme) {
    registration-geo{
        field{
            .mat-error{
                .alert{
                  position: relative !important;
                }
            }
    
            div.my-3{
                height: 40px;
                margin-bottom: 0 !important;
            }
        }
    }
}
