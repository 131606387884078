@mixin casino-filter($theme) {
  $background: map-get($theme, background);
  $border-color: map-get($theme, border-color);
  $radius: map-get($theme, radius);
  $grey-05: map-get($theme, grey-05);
  $text-on-primary: map-get($theme, text-on-primary);
  $text-color: map-get($theme, text-color);
  $primary: map-get($theme, primary);

  .casino-filter {

    .mdc-button {
      background-color: $grey-05;
      box-shadow: none;
    }

    .mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        box-shadow: none;
        height: 36px;
      }

      &-icon-suffix {
        padding: 8px 8px 0 4px;
      }

      &-subscript-wrapper {
        display: none;
      }
    }

    .mat-mdc-form-field-type-mat-input {
      .mat-mdc-form-field-infix {
        padding: .3em 0 !important;

        .mdc-text-field__input::placeholder {
          color: #{$text-color};
        }
      }
    }

    .mat-mdc-form-field-type-mat-select {
      .mat-mdc-form-field-infix {
        padding: .1em 0 !important;
      }
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }

    @include mobile {
      .right-col {
        width: 100%;
      }
    }

    .mat-badge-content {
      color: $text-on-primary;
    }

    .nav-link.active {
      background-color: $primary;
      color: $text-on-primary;
    }

    &-dialog {
      @include small-desktop {
        .mat-mdc-dialog-container {
          width: 450px;
        }
      }

      .mat-expansion-panel {
        &:not(.mat-expanded):not([aria-disabled=true]) {
          .mat-expansion-panel-header:hover {
            background: $background;
          }
        }

        &.mat-expanded {
          .mat-expansion-panel-header {
            background-color: $background;
            border-radius: $radius $radius 0 0;
            height: 40px;
          }
        }

        &-header {
          background-color: $background;
          height: 40px;

          .mat-content {
            font-weight: 700;
            text-transform: capitalize;

            span:first-child {
              margin-right: 5px;
            }
          }
        }

        &-content {
          border: 1px solid $background;
          border-radius: 0 0 $radius $radius;
          max-height: 200px;
          overflow-y: auto;

          @include tablet {
            max-height: 300px;
          }
        }

        &-body {
          .mat-mdc-checkbox + span,
          .mat-mdc-checkbox + div {
            font-weight: 700;
          }
        }
      }

      .providers-panel {
        img {
          height: 40px;
        }
      }
    }
  }
}
