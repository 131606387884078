@mixin deposit-page($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($theme, background);
  $grey-05: map-get($theme, grey-05);
  $grey-300: map-get($theme, grey-300);
  $grey-200: map-get($theme, grey-200);
  $radius: map-get($theme, radius);
  $primary: map-get($theme, primary);
  $text-color: map-get($theme, text-color);
  $border-color: map-get($theme, border-color);
  $text-on-primary: map-get($theme, text-on-primary);
  $amount-background: if($is-dark, lighten($background, 10%), $grey-05);
  $border-background: if($is-dark, $grey-300, $grey-05);

  .balance {
    &-links {
      border-bottom: 1px solid $grey-300;
      height: 45px;

      .link {
        color: $text-color;
        flex: 1;
        height: 100%;
        opacity: .7;
        padding-inline: 12px;

        .icon {
          font-size: 1.6rem;
        }

        &.active {
          border-bottom: 2px solid $primary;
          font-weight: 700;
          opacity: 1;
        }
      }
    }

    .balance-wrapper {
      display: grid;

      .payment-card {
        gap: 10px;

        .card-cnt {
          background-color: $grey-05;
          border-radius: $radius;
          height: 38px;
          width: 72px;

          img {
            display: block;
            height: 28px;
            margin-inline: auto;
            max-width: 80%;
          }
        }
      }

      .payment {
        &-amount {
          border-radius: $radius;
          border: 1px solid $grey-200;
          border-left: 4px solid $primary;
          font-weight: 600;
          padding: 6px;
        }

        &-providers {
          border-right: 1px solid $border-background;
        }

        &-details {
          back {
            .back-btn {
              color: $primary;
            }
          }
        }

        &-list {
          .single-provider {
            &.active {
              color: $primary;
            }
          }

          .group,
          .single-provider {
            height: 40px;
            padding-inline: 12px;
          }

          .group {
            background-color: $amount-background;
            font-weight: 700;
          }

          .single-provider {
            display: flex;
            background-color: transparent;
            justify-content: flex-start;
          }
        }
      }

      .amount {
        &-text {
          font-weight: 600;
        }

        &-buttons {
          gap: 8px;

          .button {
            flex: 1;
          }

          .mat-stroked-button {
            border-color: $primary;
          }
        }

        &-active {
          background-color: $primary;
          color: $text-on-primary;
        }

        &-user-info {
          //font-size: 12px;
        }
      }

      @include mobile {
        .payment {

          &-details,
          &-providers {
            &-mobile {
              display: none;
            }

            &.show {
              display: block;
            }
          }

        }

        .amount-buttons {
          .mat-stroked-button {
            //font-size: 12px;
          }
        }
      }

      @include tablet {
        grid-template-columns: 260px 1fr;

        .payment {

          &-providers {
            border-right: 1px solid $border-background;
          }

          &-list {
            .group,
            .single-provider {
              height: 40px;
              padding-inline: 12px;
            }

            .group {
              background-color: $amount-background;
              font-weight: 700;
            }

            .single-provider {
              background-color: transparent;
              justify-content: flex-start;
            }
          }

          &-details {
            margin-inline: auto;
            max-width: 500px;
          }

          &-card {
            .card-cnt {
              height: 54px;
              width: 102px;

              img {
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
}
