@mixin header($theme) {
  $header: map-get($map: $theme, $key: header);
  $border-color: map-get($map: $theme, $key: border-color);
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $primary: map-get($map: $theme, $key: primary);
  $radius: map-get($map: $theme, $key: radius);
  $background: map-get($map: $theme, $key: background);
  $success: map-get($map: $theme, $key: success);
  $sidebar-background: map-get($map: $theme, $key: sidebar-background);

  .headerFlagIcon {
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;
    height: 18px;
    width: 18px;

    svg {
      flex-shrink: 0;
      height: 100%;
      width: 50px !important;
    }
  }

  .header-container {
    .header-desktop {
      background: $header;
      height: 70px;

      .sidebar-toggle {
        &.mdc-button {
          .mdc-button__label {
            color: $text-color !important;
            padding: 10px !important;

            img {
              transform: rotate(-180deg);
            }
          }

          &.expanded {
            img {
              transform: rotate(0);
            }
          }
        }
      }

      .buttons {
        .stroked-btn {
          border-color: $text-color;
          color: $text-color;
        }
      }

      .user {
        &-balance {
          background: lighten($text-color, 95%);
          border: solid 1px black;
          border-radius: $radius;
          color: $text-color;
          font-size: 1.25rem;
          padding: 5px 5px 5px 10px;

          .mat-button {
            height: auto;

            &-base {
              font-size: 1.25rem;
              border-radius: $radius;
            }
          }
        }

        &-deposit {
          font-size: 1.25rem;
          border-radius: 0 $radius $radius 0;
          height: 38px;
        }
      }
    }

    .header-mobile {
      background: $sidebar-background;
      height: 60px;
      padding: 5px;

      .logo {
        max-width: 120px;
        width: 100%;
      }

      .stroked-btn {
        border-color: $text-on-primary !important;
        color: $text-on-primary !important;
      }

      .sidebar-toggle {
        background: transparent;
        padding-left: 0;
        padding-right: 20px;

        .menu-button {
          cursor: pointer;
          margin-left: 10px;
          margin-top: -8px;
          position: relative;
          transform: rotate(0deg);
          transition: .5s ease-in-out;
          width: 18px;

          span {
            background: $text-on-primary;
            display: block;
            height: 2px;
            left: 0;
            opacity: 1;
            position: absolute;
            transform: rotate(0deg);
            transform-origin: left center;
            transition: .25s ease-in-out;
            width: 100%;

            &:nth-child(1) {
              top: 2px;
            }

            &:nth-child(2) {
              top: 7px;
            }

            &:nth-child(3) {
              top: 12px;
            }
          }

          &.open {
            span {
              width: 24px;

              &:nth-child(1) {
                top: -1px;
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                opacity: 0;
                width: 0;
              }

              &:nth-child(3) {
                top: 16px;
                transform: rotate(-45deg);
              }
            }
          }
        }
      }

      .user {
        &-balance {
          background: rgba(255, 255, 255, 0.1);
          color: $text-on-primary;

          .plus-btn {
            font-size: 1.5rem;
            font-weight: 400;
          }

          .mat-mdc-button:not(:disabled) {
            --mdc-text-button-label-text-color: #{$text-on-primary};
          }
        }
      }
    }

    dropdown-language {
      .mat-mdc-form-field {
        &-wrapper {
          align-items: center;
          display: flex;
        }

        &-subscript-wrapper {
          display: none;
        }

        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border: 0;
          }
        }

        .mdc-floating-label {
          display: none;
        }

        &-infix {
          width: fit-content !important;

          .flag {
            @extend .headerFlagIcon;

            + span {
              display: none;
            }
          }

          .mat-select-arrow {
            color: #CBCBCB;
          }
        }
      }
    }

    .user {
      &-balance {
        font-size: 1.25rem;
        padding: 2px 2px 2px 10px;

        .mat-button {
          height: auto;

          &-base {
            border-radius: $radius;
          }
        }
      }

      &-deposit {
        border-radius: 0 $radius $radius 0;
        height: 38px;
      }

      &-icon {
        border-radius: $radius;
        padding-inline: 12px;

        md-icon {
          font-size: 20px;
        }
      }
    }
  }

  .language-dropdown {
    box-shadow: none !important;

    &.mat-mdc-select-panel .mat-mdc-option:first-child {
      border-top-left-radius: 0;
    }

    .flag {
      @extend .headerFlagIcon;
    }
  }

  .mat-menu-trigger {
    .md-icon {
      font-size: 20px;
    }
  }

  .user-menu {
    box-shadow: none;
    border-radius: $radius;
    margin-top: 25px;
    width: 234px;

    .mat-mdc-menu-content {
      padding: 0;

      .nav-item {

        a {
          align-items: center;
          display: flex;
          height: 40px;
          padding-inline: 15px;
        }

        .item-label::before {
          margin-right: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }

        &:hover {
          background: $primary;
          color: $text-on-primary;
        }
      }
    }
  }

  .balance-dropdown {
    &-content {
      background: $background;
      border-radius: $radius;

      &:first-child {
        div:first-of-type {
          border-right: 1px solid $text-on-primary;
        }
      }
    }

    .cashback-amount {
      color: $success;
      background: lighten($background, 20%);
      border-radius: 4px;
    }

    .time {
      background: $text-on-primary;
      border-radius: 50px;
    }

    .mat-flat-button {
      font-size: 12px;
      text-transform: capitalize;

      &.get-bonus-btn {
        height: 25px;
        padding-inline: 5px;
      }

      &.bonus-hub-btn {
        height: 32px;
      }
    }

    .bonus-open {
      font-size: 10px;
      background: $text-on-primary;
      border-radius: $radius;
    }
  }
}
