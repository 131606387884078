@mixin cashback($theme) {
  $text-on-primary: map-get($theme, text-on-primary);
  $radius: map-get($theme, radius);
  $background: map-get($theme, background);
  $tertiary: map-get($theme, tertiary);

  .cashback {
    &-header {
      h2 {
        font-size: 30px;
      }

      .badge {
        background: $tertiary;
        border-radius: 4px;
        color: $text-on-primary;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        max-width: 350px;
      }
    }

    .levels-card {
      background: $text-on-primary;
      border-radius: 8px;

      img {
        height: 90px;
        width: auto;
      }

      .percentage {
        font-size: 26px;
      }

      .amount,
      .deposit {
        font-size: 16px;
      }

      .deposit {
        height: 50px;
      }

      .amount {
        background: $background;
        margin-inline: -1.5rem;
      }
    }

    //swiper-slide {
    //  &:first-of-type {
    //    .percentage,
    //    img {
    //      //filter: contrast(0.2);
    //    }
    //  }
    //
    //  &:last-of-type {
    //    .deposit {
    //      color: goldenrod;
    //    }
    //
    //    .percentage {
    //      position: relative;
    //
    //      &::before {
    //        background: gold;
    //        border-radius: 4px;
    //        content: 'INVITE ONLY';
    //        display: block;
    //        font-size: 11px;
    //        left: 50%;
    //        padding: 3px 10px;
    //        position: absolute;
    //        transform: translateX(-50%);
    //        top: -5px;
    //        width: 90px;
    //      }
    //    }
    //  }
    //}
  }
}
