@mixin promotion-details($theme) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $font-size: map-get($theme, font-size);
  $text-color: map-get($theme, text-color);

  .promotion-details {
    .promo {
      &-back-btn {
        padding-inline: 10px;
        border-radius: 25px;

        .icon {
          font-size: $font-size + 7;
        }
      }
    }

    swiper-slide {
      .mat-mdc-card-content {
        height: 110px;
      }

      .promo-description {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    .mat-expansion-panel {
      &.mat-expanded {
        .mat-expansion-panel-header {
          background-color: $background;
        }
      }

      &-header {
        background-color: $background;
        height: 40px;
      }

      &-body {
        border: 1px solid $background;
        padding-block: 1rem;

        h1, h2, h3, h4 {
          color: $primary;
          padding-block: 6px;
        }

        p {
          padding-block: 6px;
        }
      }
    }
  }
}
