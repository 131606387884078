@mixin top-league($theme) {
  $icon: map-get($theme, icon);
  $font-size: map-get($theme, font-size);
  $warning: map-get($theme, warning);

  .top-league {
    padding: 20px 15px;
    position: relative;

    &-favorites {
      color: $icon;
      font-size: 16px;
      position: absolute;
      right: 5px;
      top: 5px;

      &.active {
        span {
          color: $warning;
        }
      }
    }

    &-logo {
      height: 40px;
      width: 40px;
    }

    &-content {
      .match {
        &-title {
          font-size: $font-size + 3;
          font-weight: 700;
        }

        &-league {
          font-size: $font-size - 1;
        }
      }
    }
  }
}
