@mixin games-grid($theme) {
  $radius: map-get($theme, radius);
  $text-on-primary: map-get($theme, text-on-primary);

  .games-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;

    @include tablet {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }

  .games-lite {
    position: relative;

    &.featured {
      .game-thumbnail {
        height: 100%;
      }

      .overlay {
        height: 100%;
      }
    }

    .game-thumbnail {
      border-radius: $radius;
      overflow: hidden;
    }

    .img-fluid {
      aspect-ratio: 1.5/1;
      border-radius: $radius;
      height: 100%;
      max-height: 190px;
      transition: scale .4s, blur .4s ease;
      width: 100%;
    }

    .overlay {
      color: $text-on-primary;
      height: calc(100% - 22px);
      inset: 0 0 auto;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      transition: opacity .2s ease;

      .favourites-icon {
        color: $text-on-primary;
        cursor: pointer;
        font-size: 22px;
        position: absolute;
        right: 6px;
        top: 6px;

        > .fill {
          color: red;
        }
      }

      .try-now {
        background-color: transparent;
        color: $text-on-primary;
        text-decoration: underline !important;

        &:hover {
          color: inherit;
          opacity: .8;
        }
      }

      .play-container {
        .mat-flat-button {
          height: auto;
          line-height: 32px;

          @include small-desktop {
            &:only-child {
              margin-bottom: 26px;
            }
          }

          &:not(:only-child):first-child {
            margin-bottom: 4px;
          }
        }
      }
    }

    .game-name {
      display: block;
      font-size: 1.25rem;
      margin-top: 5px;
    }

    &:hover {
      .overlay {
        opacity: 1;
        pointer-events: auto;
      }

      .img-fluid {
        scale: 1.2;
        filter: blur(4px);
      }
    }
  }
}
