@mixin bonuses($theme) {
  $border-color: map-get($theme, border-color);
  //$font-size: map-get($theme, font-size);
  $grey-700: map-get($theme, grey-700);
  $icon: map-get($theme, icon);
  $radius: map-get($theme, radius);
  $success: map-get($theme, success);
  $text-color: map-get($theme, text-color);
  $text-on-primary: map-get($theme, text-on-primary);

  .bonuses {
    .container {
      div {
        &:first-of-type {
          margin-top: 0 !important;
        }
      }

      .sport-card {
        //font-size: $font-size;
        border-radius: 0;

        .mat-mdc-card-title {
          background: darken($text-on-primary, 4.5%);
          color: $text-color;
          text-transform: capitalize;
        }

        .mat-tab-label {
          width: 100%;

          &-content {
            color: $text-color;
            font-weight: 600;
          }
        }

        .mat-card-row {
          border-bottom: 1px solid $border-color;
          //font-size: $font-size - 1;
        }

        .progress-bar-container {
          color: $text-color;
          //font-size: $font-size - 1;

          .mat-progress-bar {
            border-radius: $radius;
            color: $success;
            height: 6px;

            &-buffer {
              // background-color: lighten($grey, 70%);
            }

            &-fill::after {
              background-color: $success;
            }
          }
        }

        .card-item-box {
          border: 1px solid $grey-700;
          border-radius: $radius - 4;

          .box-title {
            color: $text-color;
            font-weight: 700;
          }
        }

        .progress-title {
          color: $text-color;
          //font-size: $font-size - 3;
        }

        .no-bonus {
          &-icon {
            color: $icon;
            font-size: 50px;
          }

          &-text {
            //font-size: $font-size;
          }
        }

        .bonus-paginator {
          .mat-paginator {
            &-container {
              justify-content: center;
            }

            :is(&-page-size, &-range-label) {
              display: none;
            }
          }
        }
      }
    }
  }
}
