@use 'variables/theme-variables';

// icomoon icons betball

@mixin icon($code, $where: 'after', $font-family: 'fontAwesome') {
  @if ($where == 'before') {
    &::before {
      content: $code;
      font-family: $font-family;
      font-size: 18px;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-weight: 100;
      text-transform: none;
      @content;
    }
  }

  @if ($where=='after') {
    &::after {
      content: $code;
      font-family: $font-family;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-weight: 100;
      text-transform: none;
      @content;
    }
  }
}

@mixin include-font($family: 'arial', $font-name: '', $weight: normal, $style: normal) {
  @font-face {
    @if ($font-name == '') {
      font-family: $family;
    }

    @if (not($font-name == '')) {
      font-family: $font-name;
    }

    font-display: fallback;
    src: url('#{$assets-dir}fonts/#{$family}.woff2') format('woff2'),
    url('#{$assets-dir}fonts/#{$family}.woff') format('woff'),
    url('#{$assets-dir}fonts/#{$family}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

// blackbet mixins

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin secondary-button() {
  border: 1px solid var(--primary);
  box-shadow: none !important;
  color: var(--primary) !important;
  margin-bottom: 0 !important;

  &:disabled {
    background-color: transparent !important;
    opacity: .6;
  }
}

/*
@mixin scrollbar-desktop() {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #AAA;
    border: none;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }
}
*/

@mixin mat-card-header-dialog() {
  &-title {
    align-items: center;
    background-color: var(--header);
    color: var(--white-color);
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    padding: 14px;

    .close-icon {
      background-color: var(--header);
      border: none;
      color: var(--text);
      font-size: 1.8rem;
      height: auto;
      line-height: 1;
      padding: 0;
    }

    .pr-1 {
      padding-right: .4rem !important;
    }
  }
}
