@mixin login-dialog($theme) {
  $text-color: map-get($map: $theme, $key: text-color);
  $text-on-primary: map-get($map: $theme, $key: text-on-primary);
  $tertiary: map-get($theme, tertiary);
  $autofill-background: $text-on-primary;

  .dialog-login {
    .mat-mdc-dialog-container {
      padding: 0;

      login-dialog {
        display: block;
        height: 100%;

        .dialog {
          height: 100%;

          &-wrapper,
          &-body {
            height: 100%;
          }

          .mat-mdc-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            margin-top: 0;
            padding: 16px;

            &-title {
              font-size: 22px;
              font-weight: 700;

              span svg{
                display: none;
              }
            }

            .mat-mdc-form-field-icon-prefix {
              padding: 0;
            }

            .login-wrapper {
              margin-inline: 0;

              .form-container {
                height: 100%;

                .login-container {
                  height: 100%;

                  @media #{$mat-lt-l} {
                    height: calc(100svh - 140px);
                  }

                  .login-form {
                    height: 100%;
                    padding-inline: 0;

                    .mat-form-field {
                      &-subscript-wrapper {
                        top: 0;
                      }

                      &-label {
                        text-transform: capitalize;
                        color: $text-color;
                      }
                    }

                    input:-webkit-autofill {
                      box-shadow: 0 0 0 1000px $autofill-background inset;
                    }

                    .button-login svg,
                    .recover-pw svg,
                    mobile-prefix .mat-form-field-subscript-wrapper {
                      display: none;
                    }

                    .w-100 {
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;

                      .row:last-child {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;

                        .button-login {
                          box-shadow: none;
                          color: $text-on-primary;
                          font-weight: 600;
                          text-transform: capitalize;
                          width: 100%;
                        }
                      }

                      .row.pb-2 {
                        padding-bottom: 15px !important;
                      }
                    }

                    .recover-pw {
                      color: $text-color;
                      cursor: pointer;
                    }

                    .mat-mdc-form-field-icon-prefix {
                      visibility: hidden;
                    }

                    .mat-form-field-infix {
                      display: flex;
                      align-items: center;
                      width: 100%;
                    }

                    .mobile-prefix-alert {
                      margin: 6.5px 0 6px;
                    }

                    .mat-form-field-type-mat-input {
                      .mat-form-field-prefix + .mat-form-field-infix {
                        .mat-form-field-label-wrapper {
                          left: 0;
                        }
                      }
                    }

                    .alert {
                      //background: transparent;

                      .md-icon {
                        font-size: 16px;
                        padding-right: 5px;
                      }
                    }

                    .btn-row {
                      padding-top: 15px;

                      .mat-flat-button {
                        width: 50%;
                      }

                      @media #{$mat-lt-md} {
                        padding-bottom: 15px;
                        padding-top: 0;

                        .mat-flat-button {
                          width: 150px;
                        }
                      }
                    }

                    .sign-up {
                      color: $tertiary;
                    }
                  }
                }
              }

              .loading-container {
                align-self: center;
                display: flex;
                flex-direction: column;
                height: 318px !important;
                justify-content: center;

                p {
                  font-size: 14px;
                  font-weight: bold;
                  margin-top: 25px;
                  text-transform: uppercase;
                }

                .icon-container {
                  font-size: 40px;
                }
              }
            }
          }
        }
      }

      @include small-desktop {
        height: 820px;
        width: 500px;

        .mat-mdc-card {
          &-title {
            margin-top: 24px;
          }

          .dialog-body {
            login {
              .form-container {
                height: 100%;

                .login-container {
                  height: 100%;

                  .button-login {
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }

      @media (max-width: 991px) {
        .mat-mdc-card {
          &-title {
            margin-bottom: 30px;
            padding: 0;
          }

          login {
            padding-inline: 0;

            .login-form {
              .w-100 {
                justify-content: start !important;
              }
            }
          }
        }
      }
    }
  }
}
