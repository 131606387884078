@mixin list-games-page($theme) {
  $text-color: map-get($theme, text-color);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $font-size: map-get($theme, font-size);
  $radius: map-get($theme, radius);
  $text-on-primary: map-get($theme, text-on-primary);

  app-swiper,
  :host {
    display: block;
  }

  section.favourites {
    .icon-heart {
      color: $primary;
      font-size: $font-size + 7;
    }
  }

  .casino {
    &-navbar {
      // position: sticky;
      // top: 0;
      // z-index: 10;

      .navbar {
        background-color: $text-on-primary;
        border-radius: $radius;
        display: flex;
        //justify-content: center;
        list-style: none;
        overflow-x: auto;

        //@include mobile {
        //  justify-content: start;
        //  max-width: 100vw;
        //
        //}

        .nav {
          &-item:hover {
            .nav-link {
              background-color: transparent;
            }
          }

          &-link {
            align-items: center;
            border-bottom: 2px solid transparent;
            color: lighten($text-color, 10%);
            display: flex;
            flex-direction: column;
            font-size: $font-size - 1;
            font-weight: 700;
            padding: 10px 12px;
            text-transform: uppercase;

            &.active {
              background-color: transparent;
              border-color: $primary;
              color: $text-color;
            }
          }

          &-icon {
            align-items: center;
            display: flex;
            font-size: $font-size + 9;
            height: 34px;
            justify-content: center;
            margin: auto;
          }
        }
      }
    }
  }

  .brands {
    .main-title {
      font-size: $font-size + 15;
    }

    .badge {
      border-radius: 4px;
      background-color: darken($background, 6%);
      padding: 2px 4px;
    }
  }

  .list-games {
    app-swiper {
      swiper-container {
        a.mat-focus-indicator {
          border-radius: 100px;
          box-shadow: none;
          font-weight: 600;
        }

        .badge {
          span {
            color: lighten($text-color, 10%);
          }
        }
      }
    }

    .no-games {
      min-height: calc(100vh - 510px);

      img {
        filter: opacity(0.2);
        width: 70px;
      }
    }
  }

  .brand-card {
    aspect-ratio: 3.3/1;
    align-items: center;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;

    &.active {
      border-color: $primary
    }
  }

  .title {
    font-size: 2rem;
  }
}
