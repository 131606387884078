body {
  color: var(--text-color);
  font-family: "Montserrat", sans-serif;
  font-size: 1rem; /* 1 rem è uguale al font-size definito nell'elemento html */
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

h1 {
  font-size: 2rem; /* Il font-size sarà il doppio del font-size dell'elemento html */
  /* Aggiungi altri stili se necessario */
}

h2 {
  font-size: 1.75rem; /* Il font-size sarà il 1.75 volte il font-size dell'elemento html */
  /* Aggiungi altri stili se necessario */
}

h3 {
  font-size: 1.5rem; /* Il font-size sarà il 1.5 volte il font-size dell'elemento html */
  /* Aggiungi altri stili se necessario */
  text-transform: capitalize;
  @media (min-width: 992px) {
    margin-bottom: 10px;
  }
}

h4 {
  font-size: 1.25rem; /* Il font-size sarà il 1.25 volte il font-size dell'elemento html */
  /* Aggiungi altri stili se necessario */
}

h5 {
  font-size: 1.125rem; /* Il font-size sarà l'1.125 volte il font-size dell'elemento html */
  /* Aggiungi altri stili se necessario */
}

h6 {
  font-size: 1rem; /* Il font-size sarà uguale al font-size dell'elemento html */
  /* Aggiungi altri stili se necessario */
}
