@mixin me-container($theme) {
  //$font-size: map-get($theme, font-size);
  $main: map-get($theme, main);
  $primary: map-get($theme, primary);
  $text-on-primary: map-get($theme, text-on-primary);

  .me-container {
    .mat-mdc-card {
      //@include mobile {
      //  height: calc(100vh - 68px);
      //}

      .mat-mdc-card-content {
        birth-place .row {
          --bs-gutter-x: 0;
          width: 100%;

          @include mobile {
            padding-inline: 10px;
          }

          @include tablet {
            --bs-gutter-x: 15px;
            --bs-gutter-y: 15px;
          }
        }

        deliveries {
          @include mobile {
            .mat-mdc-tab-body-content {
              padding-inline: 10px;
            }
          }
        }

        .mat-expansion-panel-header {
          &-title,
          .mat-content {
            //font-size: $font-size - 1;
            font-weight: 700;

            md-icon {
              display: none;
            }
          }
        }

        .mat-datepicker-toggle {
          .mat-button-focus-overlay {
            background: transparent;
          }
        }

        .button-container {
          display: flex;
          gap: 5px;
          margin-right: 15px;

          .mat-raised-button {
            &:not(.mat-button-disabled):first-child {
              border: 1px solid $primary;
              box-shadow: none;
              color: $primary;
            }
          }

          .search-btn {
            background: $primary;
            color: $text-on-primary;
          }

          md-icon {
            display: none;
          }
        }

        .empty {
          display: none;
        }

        @include mobile {
          date-range,
          causal-groups {
            .mat-form-field {
              padding-inline: 0;

              &:not(:last-of-type) {
                margin-bottom: 30px;
              }
            }
          }

          form-builder {
            .p-field {
              &:not(:last-of-type) {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
