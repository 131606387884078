@mixin play-now-live($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $grey: map-get($map: $theme, $key: grey);
  $error: map-get($map: $theme, $key: error);
  $font-size: map-get($theme, font-size);
  $grey-100: map-get($map: $theme, $key: grey-100);
  $primary: map-get($theme, primary);
  $text-on-primary: map-get($theme, text-on-primary);
  $btn-background: if($is-dark, darken($grey, 9%), $grey-100);
  $sidebar-bg: map-get($theme, sidebar-background);

  play-now-live {
    .sportsbook-swiper {
      .sportsbook-title {
        .mdi-access-point {
          color: $error;
          font-size: $font-size + 3;
        }
      }

      .mat-card.sport-card {
        .mat-card-title {
          background-color: $sidebar-bg;
          font-weight: 500;

          .card-title {
            display: flex;
            align-items: center;
          }
        }

        .play-now {
          &-content {
            padding: 28px;

            .tip-number {
              background-color: $btn-background;
              border-radius: 50%;
              height: 32px;
              width: 32px;
            }

            img {
              height: 46px;
              width: 46px;
            }

            .match-buttons {
              .match-button {
                height: 35px;
                background-color: $btn-background;
              }
            }
          }

          &-team {
            .team-score {
              background-color: $primary;
              border-radius: 2px;
              color: $text-on-primary;
              font-size: $font-size + 1;
              line-height: 1;
              padding-block: 6px;
            }

            .team-name {
              font-size: $font-size + 1;
            }

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            .middle {
              &-logo {
                height: 30px;
              }

              &-vs {
                font-size: 28px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}
