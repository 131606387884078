@mixin material-settings($theme) {
  $is-dark: map-get($map: $theme, $key: is-dark);
  $background: map-get($map: $theme, $key: background);
  $text-color: map-get($map: $theme, $key: text-color);
  $primary-color: map-get($map: $theme, $key: primary);
  $item-active-color: map-get($map: $theme, $key: primary);
  $border-color: map-get($map: $theme, $key: text);
  $default-content-background: lighten($background, 100%);
  $text-on-primary: map-get($map: $theme, $key: white-color);
  $secondary-color: map-get($map: $theme, $key: primary);
  $tertiary: map-get($map: $theme, $key: primary);
  $item-active-background: lighten($background, 3%);
  $radius: map-get($map: $theme, $key: radius);
  $title-background: lighten($background, 100%);
  $shadow-small: if($is-dark, 0 2px 2px 1px rgba(#000, .45), 0 2px 2px 1px rgba(#000, .2));
  $dialog-radius: map-get($map: $theme, $key: radius);
  $gray-background: map-get($map: $theme, $key: text);
  $gray-text: map-get($map: $theme, $key: text);
  $sidebar-background: map-get($theme, sidebar-background);
  $main: map-get($theme, text);
  $grey-100: map-get($theme, grey-100);
  $select-option: $text-on-primary;
  $icon: map-get($theme, icon);

  .mat-mdc-form-field-hide-placeholder .mat-mdc-select-placeholder {
    @include ellipsis;
  }

  .mat-expansion-panel {
    box-shadow: none !important;

    &-header.mat-expanded {
      height: 48px;
    }
  }

  .mat-calendar-body-selected {
    color: $text-on-primary;
  }

  .mat-flat-button {
    md-icon {
      span {
        display: flex;
      }
    }
  }

  .ps {
    .ps__rail-x {
      height: 3px;

      .ps__thumb-x {
        height: 3px;
      }
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-icon-button,
  .mat-stroked-button {
    min-width: unset;
  }

  hr {
    border-color: $border-color;
  }

  .mat-button-toggle-group,
  .mat-button-toggle-standalone {
    border-radius: $radius;
    box-shadow: $shadow-small;
  }

  .mat-button-toggle {
    background-color: $title-background;
    color: $text-color;
  }

  .mat-button-toggle-checked {
    background-color: $primary-color;
    color: $text-on-primary;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 1;
    padding: 10px;
  }

  .mat-paginator-range-actions .mat-button-base {
    padding: 0 !important;
  }

  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-mdc-dialog-container {
    border-radius: $dialog-radius;
    padding: 0;
    width: 100vw;

    @media screen and (min-width: 0) and (max-width: 1023px) {
      border-radius: 0;
    }
  }

  .mat-mdc-select-panel-wrap .mat-mdc-select-panel {
    min-width: unset;
  }

  .mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
    line-height: 1.5;
    white-space: normal;
  }

  .mat-mdc-slide-toggle .mat-mdc-slide-toggle-ripple {
    top: calc(50% - 40px);
  }

  .mat-button-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .mat-mdc-progress-bar-background {
    border-radius: var(--radius);
  }

  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background: darken($text-on-primary, 5%);
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-spinner circle {
    stroke: $tertiary;
  }

  .mat-mdc-tab-label {
    height: 44px;
  }

  .mat-mdc-tab-group.mat-primary .mat-ink-bar,
  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary);
  }

  .mat-mdc-tab-group.hide-tabs {
    .mat-mdc-tab-header {
      display: none;
    }
  }

  .mat-mdc-tab-group {
    .mat-mdc-tab-labels {
      border-bottom: 1px solid var(--mat-divider-color);
    }
  }

  /*.mat-tab-body-content {
    @include scrollbar-desktop();
  }*/

  .mat-mdc-checkbox {
    --mat-option-selected-state-label-text-color: #{$text-on-primary};

    .mdc-checkbox__background {
      --mdc-checkbox-selected-icon-color: #{$item-active-color};
      --mdc-checkbox-selected-hover-icon-color: #{$item-active-color};
      --mdc-checkbox-selected-pressed-icon-color: #{$item-active-color};
      --mdc-checkbox-selected-focus-icon-color: #{$item-active-color};
      --mdc-checkbox-selected-checkmark-color: #{$text-on-primary};
    }
  }

  .mat-primary {
    .mat-mdc-option.mat-mdc-selected:not(.mat-option-disabled) {
      color: $item-active-color;
    }
  }

  .mat-paginator-container {
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0;

    .mat-paginator-range-label {
      margin: 0
    }
  }

  .mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-outline {
      color: $border-color;
    }

    .mat-mdc-form-field-label {
      color: $item-active-color;
    }
  }

  .mat-mdc-form-field {
    //  display: block;
    width: 100%;

    .mat-mdc-form-field-label {
      color: $gray-text;
      transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00107px);
    }

    .mat-mdc-form-field-wrapper {
      .mat-form-field-flex {
        //background: $gray-background;
        border-radius: 4px;
        height: 40px;
      }

      .mat-form-field-infix {

        .mat-form-field-label {
          color: $text-color;

          mat-label {
            span::after {
              display: none;
            }
          }
        }
      }

      .mat-form-field-suffix {
        color: $text-color;
      }
    }

    &.mat-focused {
      .mat-form-field-label {
        color: $item-active-color;
      }
    }
  }

  .mat-form-field-disabled {
    .mat-form-field-flex,
    .mat-form-field-infix {
      background: transparent;
      cursor: not-allowed;

      .mat-input-element,
      .mat-select > .mat-select-trigger,
      .mat-radio-button > .mat-radio-label {
        cursor: not-allowed;
      }
    }
  }

  .mat-drawer-container {
    background: transparent;
  }

  .mat-mdc-card,
  .mat-mdc-dialog-container,
  .mat-menu-panel {
    background: $default-content-background;
  }

  //.mat-tab-nav-bar,
  //.mat-tab-header {
  //  border-bottom: 1px solid inherit;
  //}

  .mat-tab-header .mat-tab-header-pagination {
    min-width: 30px;
  }

  .mat-table,
  .mat-paginator {
    background: transparent;
  }

  .mat-header-cell,
  .mat-cell {
    padding: 5px;
    white-space: nowrap;
  }

  .mat-mdc-tab-labels {
    font-weight: normal;
    min-width: 1px !important;
    text-transform: capitalize;

    .mdc-tab {
      height: 44px;
      min-width: 1px !important;
      padding: 0 12px !important;

      &__text-label {
        font-size: 1.08rem;
        opacity: .6;
        --mat-tab-header-active-label-text-color: #{$text-color};
        --mat-tab-header-active-focus-label-text-color: #{$text-color};
        --mat-tab-header-active-hover-label-text-color: #{$text-color};
        --mat-tab-header-active-focus-indicator-color: #{$text-color};
        --mat-tab-header-active-hover-indicator-color: #{$text-color};
      }
    }

    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-shape: #{$item-active-color};
    }

    .mdc-tab--active,
    .mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
      background-color: $item-active-background;
    }

    .mdc-tab--active .mdc-tab__text-label,
    .mdc-tab--active:hover .mdc-tab__text-label {
      font-weight: bold;
      opacity: 1 !important;
    }
  }

  .mat-mdc-card {
    overflow: hidden;
  }

  &.is-mobile {
    .mat-mdc-dialog-container {
      border-radius: 0;
    }

    .mat-select-panel {
      min-width: 100% !important;
    }
  }

  .mat-form-field-appearance-fill,
  .mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      border-bottom: .1em solid transparent;
      border-top: .4em solid transparent;
      min-height: auto;
    }
  }

  .mat-mdc-form-field-label-wrapper {
    padding-top: .4em;
    top: -.4em;
  }

  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    height: 40px;
    margin: 0;
    padding-bottom: 0;
  }

  .mat-mdc-form-field-prefix {
    margin-right: 5px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: .6em 0;
    width: 180px;
  }

  .mat-mdc-form-field.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-infix {
    padding: .4em 0 .2em;
  }

  .mat-mdc-form-field-icon-suffix {
    .mat-icon-button {
      background-color: transparent;
      color: var(--mat-select-enabled-arrow-color);
      height: auto;
      line-height: normal;
      padding: 11px 8px !important;
      width: auto;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      height: auto;
      padding: 0;
      width: auto
    }
  }

  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-table {
    border-spacing: 0;
    font-family: 'Roboto', sans-serif;
    width: 100%;

    .mat-row,
    .mat-header-row {
      height: 50px;
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 24px;
    }

    td.mat-cell {
      border-bottom: var(--mat-table-row-item-outline-width, 1px) solid var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
    }

    th.mat-header-cell {
      font-size: 12px;
      font-weight: 500;
      text-align: left;
    }

  }

  .mat-mdc-table {
    .mat-mdc-header {
      &-row {
        --mat-table-header-container-height: 34px;
        --mat-table-header-headline-size: 12px;
        --mat-table-header-headline-color: #{$sidebar-background};

        background-color: $grey-100;
      }
    }

    .mat-mdc-row {
      --mat-table-row-item-container-height: 44px;
    }
  }

  .mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: var(--mat-table-row-item-outline-width, 1px) solid var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12));
  }

  .mat-flat-button.mat-primary {
    color: rgba($text-on-primary, .85);
    font-weight: bold;

    &:hover {
      color: $text-on-primary;
    }
  }

  .mat-mdc-progress-bar-fill::after {
    background: linear-gradient(90deg, #35CC37 -0.03%, #2AB02C 171.45%);
  }

  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: 6px;
    padding: 0 !important;
    position: relative;
  }

  .mdc-floating-label--float-above {
    --mdc-outlined-text-field-label-text-color: #{$text-color};
    --mdc-outlined-text-field-disabled-label-text-color: #{$text-color};
  }

  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;
    position: relative;
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none
  }

  .mat-progress-warn {
    .mat-progress-bar-fill::after {
      background: linear-gradient(90deg, #E3321A -0.03%, #C22510 171.45%);
    }
  }

  .mat-menu-item {
    height: 32px;
    line-height: 32px;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0;

    &__native-control {
      height: 20px;
      width: 20px;
    }
  }

  div.mat-mdc-select-panel {
    border-radius: 8px !important;
    padding: 0;

    &.language-dropdown {
      @include mobile {
        border-radius: 0 !important;
        width: 100vw;
      }
    }

    .mat-mdc-optgroup-label,
    .mat-mdc-option {
      background-color: $text-on-primary;
      color: $text-color;
      height: 40px;
      min-height: auto;

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:hover {
        background-color: $primary-color;
        color: $text-on-primary;
      }

      .mdc-list-item__primary-text {
        align-items: center;
        display: flex;
      }
    }
  }

  .mdc-checkbox {
    padding: 0;

    .mdc-checkbox__background {
      left: 0;
      top: 0;
    }
  }

  .mat-mdc-checkbox-touch-target,
  .mdc-checkbox .mdc-checkbox__native-control {
    height: auto;
    width: auto;
  }

  .cdk-overlay-dark-backdrop {
    //background: rgba($overlaybackground, .88);
  }

  .mat-input-element {
    color: $text-color;

    &:disabled {
      color: $text-color;
    }
  }

  .mat-radio-button {
    color: $text-color;

    &.mat-radio-disabled {
      border-color: $text-color;
      color: $text-color;
    }
  }

  .mat-select-value {
    color: $text-color;
  }

  .mat-stepper-horizontal {
    background: $background;
    width: 100%;

    .mat-step-header {
      flex: 1;

      md-icon {
        font-size: 20px;
      }

      .mat-icon {
        height: 24px;
        width: 24px;
      }

      .mat-step-icon {
        background-color: var(--icon);
        color: var(--white-color);
        top: -7px;
      }

      .mat-step-icon-selected {
        background-color: $secondary-color;
        color: $text-on-primary;
      }


      .mat-step-optional {
        color: $gray-text;
      }

      .mat-step-label.mat-step-label-active {
        color: var(--text);
        font-weight: bold;
      }

    }

  }

  .mat-mdc-form-field:not(.mat-form-field-disabled) {
    input:-webkit-autofill,
    .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
      box-shadow: 0 0 0 1000px $default-content-background inset;
    }
  }

  input:-webkit-autofill,
  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    border-radius: $radius;
    caret-color: $main;
    color: #F0F0F0;
    -webkit-text-fill-color: $main;
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $primary-color;
  }

  .mat-form-field.dropdown-search-filter {
    background: $text-on-primary;
    padding: 0 10px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  mobile-prefix.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: inherit;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-icon-suffix {
    color: $icon;

    md-icon {
      font-size: 16px;
    }

    .mat-mdc-icon-button {
      height: 1.5em;
      width: 1.5em;
    }

    .mat-datepicker-toggle {
      .mat-datepicker-toggle-default-icon {
        --mdc-icon-button-icon-size: 20px;
        transform: translateY(5px);
      }
    }
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0);
  }

  form-builder {
    .mat-form-field-label-wrapper {
      left: 0;
      top: -6px;
    }

    .mat-datepicker-toggle {
      margin-right: 8px;

      button.mat-icon-button.mat-button-base {
        padding: 0;
      }

      .mat-button-wrapper {
        display: block;

        svg.mat-datepicker-toggle-default-icon {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  agent-login-page .mat-mdc-form-field-subscript-wrapper {
    position: relative;
  }

  .mat-mdc-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: $text-on-primary;
    color: $text-color;
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #{$primary-color};
    --mdc-radio-selected-hover-icon-color: #{$primary-color};
    --mdc-radio-selected-icon-color: #{$primary-color};
    --mdc-radio-selected-pressed-icon-color: #{$primary-color};
    --mat-radio-checked-ripple-color: #{$primary-color};
  }

  .mat-select-panel-wrap .mat-select-panel .mat-option {
    &-text {
      display: flex;
      align-items: center;
    }

    background-color: $select-option;
    height: 40px;

    &:hover {
      background-color: lighten($primary-color, 2%);
      color: $text-on-primary;
    }
  }

  .mat-mdc-form-field-icon-prefix {
    padding-left: 1em;
  }
}
